import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { mergePrismicPreviewData } from "gatsby-source-prismic"

import { NextPage, SEO } from "components"

// Page sections
import BlogFeed from "./_blogFeed"
import IntroSection from "./_introSection"
import FeaturedPosts from "./_featuredPosts"

function BlogPage() {
  const staticData = useStaticQuery(graphql`
    {
      prismicBlog {
        data {
          hero_title {
            text
          }
          hero_description {
            html
          }
          featured_posts {
            blog_post {
              document {
                ... on PrismicBlogPost {
                  uid
                  type
                  data {
                    blog_image {
                      fluid(maxWidth: 600) {
                        ...GatsbyPrismicImageFluid
                      }
                      alt
                    }
                    blog_tag {
                      document {
                        ... on PrismicBlogTag {
                          data {
                            name
                            color
                          }
                        }
                      }
                    }
                    title {
                      text
                    }
                    subtitle
                    date
                  }
                }
              }
            }
          }
          seo_title {
            text
          }
          seo_description {
            text
          }
          seo_image {
            url
            alt
          }
          next_page_image {
            fluid(maxWidth: 600) {
              ...GatsbyPrismicImageFluid
            }
            alt
          }
          next_page_title {
            text
          }
          next_page_button_text
          next_page_button_link {
            slug
          }
        }
      }
      allPrismicBlogTag {
        nodes {
          data {
            color
            name
          }
        }
      }
      allPrismicBlogPost(sort: { fields: data___date, order: DESC }) {
        nodes {
          uid
          type
          data {
            blog_image {
              fluid(maxWidth: 600) {
                ...GatsbyPrismicImageFluid
              }
              alt
            }
            blog_tag {
              document {
                ... on PrismicBlogTag {
                  data {
                    color
                    name
                  }
                }
              }
            }
            date
            title {
              text
            }
            subtitle
          }
        }
      }
    }
  `)
  const previewData =
    typeof window !== "undefined" && window.__PRISMIC_PREVIEW_DATA__
  const data = mergePrismicPreviewData({ staticData, previewData }).prismicBlog
    .data

  const tags = mergePrismicPreviewData({ staticData, previewData })
    .allPrismicBlogTag.nodes

  let blogItems = []
  blogItems = mergePrismicPreviewData({ staticData, previewData })
    .allPrismicBlogPost.nodes

  if (data)
    return (
      <>
        <SEO
          title={data.seo_title && data.seo_title.text}
          description={data.seo_description && data.seo_description.text}
          image={data.seo_image && data.seo_image.url}
          imageAlt={data.seo_image && data.seo_image.alt}
        />
        {/* ============ INTRO ============ */}
        <IntroSection
          id="blog-intro"
          data={{
            title: data.hero_title && data.hero_title.text,
            bodyContent: data.hero_description && data.hero_description.html,
          }}
        />

        {/* ============ FEATURED POSTS ============ */}
        <FeaturedPosts id="blog-featured" {...data} />

        {/* ============ BLOG FEED ============ */}
        <BlogFeed id="blog-feed" blogItems={blogItems} tags={tags} />

        {/* ============ NEXT PAGE ============ */}
        <NextPage
          id="blog-nextPage"
          image={data.next_page_image}
          title={data.next_page_title && data.next_page_title.text}
          buttonLabel={data.next_page_button_text}
          buttonTo={"/" + data.next_page_button_link.slug + "/"}
        />
      </>
    )
  else return null
}

export default BlogPage

import React from "react"
import Image from "gatsby-image/withIEPolyfill"
import { useEmblaCarousel } from "embla-carousel/react"
import dayjs from "dayjs"

import {
  Box,
  Flex,
  Heading,
  IconButton,
  Link,
  Paragraph,
  Text,
  Wrapper,
} from "components"
import { prismicColor } from "utils"
import { mdiArrowLeft, mdiArrowRight } from "@mdi/js"

function BlogCard({ to, blog_image, blog_tag, date, subtitle, title }) {
  let CARD_COLOR = prismicColor(
    blog_tag &&
      blog_tag.document &&
      blog_tag.document.data &&
      blog_tag.document.data.color
  ).background

  if (title && title.text)
    return (
      <Box
        position="relative"
        flex="0 0 100%"
        ml="layout.5"
        overflow="hidden"
        borderRadius={8}
      >
        <Flex
          as={Link}
          to={to}
          flexDirection={["column", "row"]}
          width="100%"
          height="100%"
        >
          <Box position="relative" flex={1} overflow="hidden" bg="wash">
            <Box position="relative" pb="100%">
              {/* Image */}
              {blog_image && blog_image.fluid && (
                <Image
                  fluid={blog_image.fluid}
                  alt={blog_image.alt}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                  objectFit="cover"
                  objectPosition="center"
                />
              )}
              <Flex
                position="absolute"
                top={0}
                left={0}
                p={["layout.2", null, "layout.3"]}
                width="100%"
                height="100%"
                justifyContent="flex-end"
                alignItems="flex-start"
              >
                {blog_tag && blog_tag.document && blog_tag.document.data && (
                  <Box bg={CARD_COLOR} py="6px" px="layout.1">
                    <Text
                      size={400}
                      color="background"
                      style={{ textTransform: "lowercase" }}
                    >
                      {blog_tag.document.data.name}
                    </Text>
                  </Box>
                )}
              </Flex>
            </Box>
          </Box>
          {/* Colored dividing line */}
          <Box width={["100%", 8]} height={[8, "100%"]} bg={CARD_COLOR} />
          {/* Text */}
          <Flex flex={1} bg="wash">
            <Flex
              flexDirection="column"
              justifyContent="space-between"
              px="layout.4"
              pt={[32, null, 56]}
              pb="layout.2"
            >
              <Box>
                {title && title.text && (
                  <Heading size={600} mb="layout.2" color="text">
                    {title.text}
                  </Heading>
                )}
                {subtitle && (
                  <Paragraph
                    size={500}
                    style={{
                      display: "-webkit-box",
                      WebkitLineClamp: 3,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                    }}
                  >
                    {subtitle}
                  </Paragraph>
                )}
              </Box>
              <Flex justifyContent="space-between" py="layout.2">
                {date && (
                  <Text size={300} color="alt">
                    {dayjs(date).format("MMMM DD, YYYY")}
                  </Text>
                )}
                <Text size={400} color={CARD_COLOR}>
                  READ MORE
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Box>
    )
  else return null
}

const FeaturedPosts = ({ featured_posts, id }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel()
  const [scrollProgress, setScrollProgress] = React.useState(0)

  const scrollPrev = React.useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev()
  }, [emblaApi])

  const scrollNext = React.useCallback(() => {
    if (emblaApi) emblaApi.scrollNext()
  }, [emblaApi])

  const onScroll = React.useCallback(() => {
    if (!emblaApi) return
    const progress = Math.max(0, Math.min(1, emblaApi.scrollProgress()))
    setScrollProgress(progress * 100)
  }, [emblaApi, setScrollProgress])

  React.useEffect(() => {
    if (!emblaApi) return
    onScroll()
    emblaApi.on("scroll", onScroll)
  }, [emblaApi, onScroll])

  if (
    featured_posts &&
    featured_posts[0] &&
    featured_posts[0].blog_post &&
    featured_posts[0].blog_post.document &&
    featured_posts[0].blog_post.document.data
  )
    return (
      <Box>
        <Wrapper id={id} pt={["layout.6", "layout.8"]}>
          <Flex justifyContent="space-between" alignItems="center">
            <Paragraph size={500}>Featured Posts</Paragraph>
            <Flex>
              <IconButton
                icon={mdiArrowLeft}
                appearance="primary"
                height={40}
                onClick={scrollPrev}
              />
              <IconButton
                icon={mdiArrowRight}
                appearance="primary"
                height={40}
                onClick={scrollNext}
                ml="layout.1"
              />
            </Flex>
          </Flex>
        </Wrapper>
        <Wrapper pt={0} overflow="hidden">
          <Box ref={emblaRef}>
            <Flex>
              {featured_posts.map((item, index) => {
                if (
                  item &&
                  item.blog_post &&
                  item.blog_post.document &&
                  item.blog_post.document.data
                )
                  return (
                    <BlogCard
                      to={item.blog_post.document}
                      {...item.blog_post.document.data}
                      key={"blogPostCard" + index}
                    />
                  )
                else return null
              })}
            </Flex>
          </Box>
          <Box
            position="relative"
            height={4}
            mt="layout.3"
            bg="wash"
            overflow="hidden"
          >
            <Box
              position="absolute"
              top={0}
              bottom={0}
              left="-100%"
              width="100%"
              bg="border"
              style={{ transform: `translateX(${scrollProgress}%)` }}
            />
          </Box>
        </Wrapper>
      </Box>
    )
  else return null
}

export default FeaturedPosts

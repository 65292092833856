import React from "react"

// Project components
import { Box, Heading, RichText, Wrapper } from "components"

const IntroSection = ({ data, id }) => {
  if (data.title || data.bodyContent)
    return (
      <Box>
        <Wrapper id={id} pt={196} pb="layout.4">
          {/* Title */}
          {data.title && <Heading size={900}>{data.title}</Heading>}

          {/* Content */}
          {data.bodyContent && (
            <RichText
              content={{
                html: data.bodyContent,
              }}
              size={600}
              mt={["layout.3", "layout.5"]}
            />
          )}
        </Wrapper>
      </Box>
    )
  else return null
}

export default IntroSection

function prismicColor(color) {
  let cardColor = {
    background: "",
    heading: "",
    text: "",
  }

  switch (color) {
    case "Blue":
      cardColor.background = "brand.primary"
      cardColor.heading = "background"
      cardColor.text = "background"
      break
    case "Green":
      cardColor.background = "brand.secondary"
      cardColor.heading = "background"
      cardColor.text = "background"

      break
    case "Gray":
      cardColor.background = "wash"
      cardColor.heading = "black"
      cardColor.text = "black"

      break
    default:
      cardColor.background = "black"
      cardColor.heading = "background"
      cardColor.text = "background"
  }

  return cardColor
}

export default prismicColor

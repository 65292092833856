import React, { useState } from "react"
import Image from "gatsby-image/withIEPolyfill"
import css from "@styled-system/css"
import Masonry from "react-masonry-css"
import { Global } from "@emotion/react"
import dayjs from "dayjs"

import {
  AspectRatio,
  Box,
  Button,
  Flex,
  Heading,
  Input,
  Link,
  Paragraph,
  Text,
  Wrapper,
} from "components"
import { prismicColor } from "utils"

function BlogCard({ to, blog_image, blog_tag, date, subtitle, title }) {
  let CARD_COLOR = prismicColor(
    blog_tag &&
      blog_tag.document &&
      blog_tag.document.data &&
      blog_tag.document.data.color
  ).background

  if (title && title.text)
    return (
      <Box
        as={Link}
        display="block"
        to={to}
        bg="wash"
        borderRadius={8}
        mb={32}
        overflow="hidden"
      >
        {/* Image */}
        {blog_image && blog_image.fluid && (
          <AspectRatio
            ratio="16:9"
            bg="background"
            css={css({ overflow: "hidden" })}
          >
            <Image
              fluid={blog_image.fluid}
              alt={blog_image.alt}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
              objectFit="cover"
              objectPosition="center"
            />
            <Flex
              position="absolute"
              top={0}
              left={0}
              p={["layout.2", null, "layout.3"]}
              width="100%"
              height="100%"
              justifyContent="flex-end"
              alignItems="flex-start"
            >
              {blog_tag && blog_tag.document && blog_tag.document.data && (
                <Box bg={CARD_COLOR} py="6px" px="layout.1">
                  <Text
                    size={400}
                    color="background"
                    style={{ textTransform: "lowercase" }}
                  >
                    {blog_tag.document.data.name}
                  </Text>
                </Box>
              )}
            </Flex>
          </AspectRatio>
        )}

        {/* Colored dividing line */}
        <Box height={8} bg={CARD_COLOR} />

        {/* Text */}
        <Box px="layout.3" pt="layout.2">
          {blog_tag &&
            blog_tag.document &&
            blog_tag.document.data &&
            blog_tag.document.name && (
              <Box bg={CARD_COLOR}>
                <Heading size={300} color="background">
                  {blog_tag.document.data.name}
                </Heading>
              </Box>
            )}
          {title && title.text && (
            <Heading size={600} mb="layout.2" color="text">
              {title.text}
            </Heading>
          )}
          {subtitle && (
            <Paragraph
              size={400}
              style={{
                display: "-webkit-box",
                WebkitLineClamp: 3,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
              }}
            >
              {subtitle}
            </Paragraph>
          )}
          <Flex justifyContent="space-between" py="layout.4">
            {date && (
              <Text size={300} color="alt">
                {dayjs(date).format("MMMM DD, YYYY")}
              </Text>
            )}
            <Text size={400} color={CARD_COLOR}>
              READ MORE
            </Text>
          </Flex>
        </Box>
      </Box>
    )
  else return null
}

function BlogFeed({ blogItems, id, tags }) {
  const [search, setSearch] = useState("")
  const [activeTag, setActiveTag] = useState("")

  if (blogItems && blogItems[0] && blogItems[0].data)
    return (
      <Wrapper id={id} pt={["layout.4", "layout.6"]}>
        {/* Blog tag nav and search bar */}
        <Flex
          justifyContent="space-between"
          flexDirection={["column", "column", "column", "row"]}
          mb={[8, 9]}
        >
          {tags && tags[0] && tags[0].data && tags[0].data.name && (
            <Flex flexWrap="wrap">
              <Box
                as="button"
                px={4}
                py={3}
                css={{
                  appearance: "none",
                  cursor: "pointer",
                  color: "brand.tertiary",
                  borderRadius: 9999,
                  border: "none",
                }}
                style={{
                  backgroundColor: activeTag === "" ? "black" : "transparent",
                }}
                onClick={() => {
                  setActiveTag("")
                }}
              >
                <Text
                  size={400}
                  fontWeight="bold"
                  color="brand.tertiary"
                  style={{
                    whiteSpace: "nowrap",
                    color: activeTag === "" && "white",
                  }}
                >
                  All Topics
                </Text>
              </Box>
              {tags.map((item, index) => {
                if (item && item.data && item.data.name)
                  return (
                    <Box
                      as="button"
                      key={"blogTag" + index}
                      py={3}
                      px={4}
                      css={{
                        whiteSpace: "nowrap",
                        appearance: "none",
                        cursor: "pointer",
                        color: "brand.tertiary",
                        borderRadius: 9999,
                        border: "none",
                        transition: "all 200ms ease-out",
                      }}
                      style={{
                        backgroundColor:
                          item.data.name === activeTag
                            ? "black"
                            : "transparent",
                      }}
                      onClick={() => {
                        setActiveTag(item.data.name)
                      }}
                    >
                      <Text
                        size={400}
                        fontWeight="bold"
                        color="brand.tertiary"
                        style={{
                          color: item.data.name === activeTag && "white",
                        }}
                      >
                        {item.data.name}
                      </Text>
                    </Box>
                  )
              })}
            </Flex>
          )}
          <Box mt={[4, 4, 4, 0]} position="relative">
            <Input
              name="search"
              label="Search"
              type="text"
              placeholder="Search"
              value={search}
              onChange={e => {
                setSearch(e.target.value)
              }}
              style={{ paddingLeft: 36 }}
            />
            <Box position="absolute" top={"10px"} left={3}>
              <svg
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.5259 13.8476L19.0951 17.4159L17.9159 18.5951L14.3476 15.0259C13.0199 16.0903 11.3684 16.6692 9.66675 16.6667C5.52675 16.6667 2.16675 13.3067 2.16675 9.16675C2.16675 5.02675 5.52675 1.66675 9.66675 1.66675C13.8067 1.66675 17.1667 5.02675 17.1667 9.16675C17.1692 10.8684 16.5903 12.5199 15.5259 13.8476ZM13.8542 13.2292C14.9118 12.1417 15.5025 10.6838 15.5001 9.16675C15.5001 5.94341 12.8892 3.33341 9.66675 3.33341C6.44341 3.33341 3.83341 5.94341 3.83341 9.16675C3.83341 12.3892 6.44341 15.0001 9.66675 15.0001C11.1838 15.0025 12.6417 14.4118 13.7292 13.3542L13.8542 13.2292Z"
                  fill="#939497"
                />
              </svg>
            </Box>
          </Box>
        </Flex>
        {/* Blog Cards */}
        <Global
          styles={css({
            ".feed-grid": {
              display: "flex",
              marginLeft: -32,
              width: "auto",
            },
            ".feed-grid_column": {
              paddingLeft: 32,
              backgroundClip: "padding-box",
            },
          })}
        />
        <Masonry
          breakpointCols={{
            default: 2,
            512: 1,
          }}
          className="feed-grid"
          columnClassName="feed-grid_column"
        >
          {blogItems
            .filter(a => {
              let filtered = false
              if (activeTag) {
                if (
                  a.data.blog_tag &&
                  a.data.blog_tag.document &&
                  a.data.blog_tag.document.data &&
                  a.data.blog_tag.document.data.name === activeTag
                ) {
                  filtered = true
                }
              } else {
                filtered = true
              }
              return filtered
            })
            .filter(a => {
              let filtered = false
              if (
                JSON.stringify(a)
                  .toLowerCase()
                  .indexOf(search.toLowerCase()) !== -1 ||
                !search
              ) {
                filtered = true
              }
              return filtered
            })
            .map((item, index) => {
              if (item && item.data)
                return (
                  <BlogCard
                    to={item}
                    {...item.data}
                    key={"blogPostCard" + index}
                  />
                )
              else return null
            })}
        </Masonry>
      </Wrapper>
    )
  else return null
}

export default BlogFeed
